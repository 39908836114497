/**
 * This is useful for stopping and abortting events.
 *
 * @param {EventObect} event The event that was triggered
 * @returns false
 */
export function fn(event) {
  event.stopPropagation();
  event.preventDefault();
  return false;
}

/**
 * This is useful for ensuring something should wait for a given time before procedding.
 *
 * Eg.:
 *   await sleep(1000); // will wait for 1 second
 *
 * @param {Number} ms Mileseconds
 * @returns Promise
 */
export async function sleep(ms) {
  return await new Promise((resolve) => setTimeout(resolve, ms));
}

/**
 * Will remove accents (diacritics) from a given string, replacing them to the actual,
 * correct characters.
 *
 * Eg.:
 *   removeDiacritics("Um textão contendo acentuação.");
 *  returns "Um textao contendo acentuacao."
 * @param {String} word The text to be treated
 * @param {String} wordSeparator An optional sepparator to be used (defaults to " ")
 * @returns String
 */
export function removeDiacritics(word, wordSeparator = ' ') {
  return word
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/ /g, wordSeparator);
}

/**
 * Will apply a cammelCase format for the given string.
 * Eg.:
 *   camelize("some-random text");
 *   returns: "someRandomText"
 * Eg.:
 *   camelize("some-random text", true);
 *   returns: "SomeRandomText"
 *
 * @param {String} str The text to be treated
 * @param {Boolean} upper Trus if the first letter should also be upper cased
 * @returns String
 */
export function camelize(str, upper = false) {
  return str
    // eslint-disable-next-line no-misleading-character-class
    .replace(/(?:^\w|[A-Z\u0300-\u036f]|\b\w)/g, function (word, index) {
      return index === 0 && !upper ? word.toLowerCase() : word.toUpperCase();
    })
    .replace(/[\s_-]+/g, '');
}

/**
 * Will take a string and try to make it look like a word (or a set of words).
 * Eg.:
 *   wordfy("some-randomTextWith_different tempére");
 *   returns: "some random Text With different tempére"
 *
 * @param {String} word The text to be treated
 * @returns String
 */
export function wordfy(word) {
  return word.replace(/[-_ @/><?&%!]/g, ' ').replace(/([^^])([A-Z])/g, '$1 $2');
}

/**
 * Will transform a text into a snake_case format.
 * Eg.:
 *   snakefy("some random-text-withDifferentFormats");
 *   returns: "some_random_text_with_different_formats"
 *
 * @param {String} word The text to be transformed
 * @returns String
 */
export function snakefy(word, char = '_') {
  word = wordfy(word);
  return word.replace(/ /g, char).toLowerCase();
}

/**
 * Transforms a given text into a slugified text.
 *
 * Eg.:
 *  slugfy("Um texto com acentuação e LETRAS MAIÚSCULAS");
 *  returns "um-texto-com-acentuacao-e-letras-maiusculas"
 *
 * @param {String} val The text to be treated
 * @returns String
 */
export function slugfy(val, options = {}) {
  let slug = removeDiacritics(val, '-')
    .replace(/[^a-z0-9-]/gi, '')
    // .replace(/-$/, '')
    .replace(/--/g, '-')
    .toLowerCase();

  if (options.startWithSlash) {
    slug = '/' + slug;
  }
  if (options.trailingSlash) {
    slug += '/';
  }
  return slug.replace(/\/\//g, '/');
}

/**
 * Will format a value in a localized money/currency.
 *
 * Eg.:
 *   currency(123456789)
 *   returns: "R$123.456.789,00"
 * Eg.:
 *   currency(123456789, 'en-US')
 *   returns: "R$123,456,789.00"
 * Eg.:
 *   currency(123456789, 'en-US', "USD")
 *   returns: "$123,456,789.00"
 * Eg.:
 *   currency(123456789, 'es-AR', "ARS")
 *   returns: "$123.456.789,00"
 *
 * @param {Number} value The value
 * @param {String} lang The language into which the currency shall be localized (default is "pt-BR")
 * @param {String} currency The currency international id in 3 letters (default is "BRL")
 * @returns String
 */
export function currency(value, lang = 'pt-BR', currency = 'BRL') {
  return new Intl.NumberFormat(lang, {
    style: 'currency',
    currency
  }).format(value);
}

let isIOS = -1;
/**
 * Will check wether the current Operating System is iOS or not
 *
 * @returns Boolean
 */
export function iOS() {
  // isIOS will cache it
  if (isIOS === -1) {
    isIOS =
      [
        'iPad Simulator',
        'iPhone Simulator',
        'iPod Simulator',
        'iPad',
        'iPhone',
        'iPod'
      ].includes(navigator.platform) ||
      // iPad on iOS 13 detection
      (navigator.userAgent.includes('Mac') && 'ontouchend' in document);
  }
  return isIOS;
}

/**
 * Calculates the aspect ratio for a given asset based on its
 * original size and max available area.
 *
 * @param {Number} srcWidth The original width
 * @param {Number} srcHeight The original height
 * @param {Number} maxWidth The limit width
 * @param {Number} maxHeight The limit for the height
 * @returns Object {width, height}
 */
export function calculateAspectRatioFit(
  srcWidth,
  srcHeight,
  maxWidth,
  maxHeight
) {
  const ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight);
  return { width: srcWidth * ratio, height: srcHeight * ratio };
}

/**
 * Generates the current (and formated) date and time.
 *
 * @returns {object} currentDate: string no formato "DD/MM/YYYY"
 *                   currentTime: string no formato "HH:MM:SS GMT-0300 (Brasilia Standard Time)"
 */
export function getDateAndTime() {
  const dateTime = new Date();
  const curDate = Intl.DateTimeFormat('pt-BR').format(dateTime);
  const curTime = dateTime.toTimeString();
  return {
    currentDate: curDate,
    currentTime: curTime
  };
}

/**
 * Converts, if needed, date from ISO to BR
 *
 * @param {string} inputDate Original date in format YYYY-MM-DD
 * @returns String Aformated string to the format DD/MM/YYYY
 */
export function dateIso2Br(inputDate) {
  let converted = String(inputDate).trim();
  if (converted.length > 0 && converted.indexOf('-') >= 0) {
    converted = converted.match(/\d+/g);
    const [year, month, day] = converted;
    converted = `${day}/${month}/${year}`;
  }
  return converted;
}

/**
 * Converte, se necessário, data de BR para ISO
 *
 * @param {string} inputDate Data de entrada no formato DD/MM/YYYY
 * @returns Data de saída no formato YYYY-MM-DD
 */
export function dateBr2Iso(inputDate) {
  let converted = String(inputDate).trim();
  if (converted.length > 0 && converted.indexOf('/') >= 0) {
    converted = converted.match(/\d+/g);
    const [day, month, year] = converted;
    converted = `${year}-${month}-${day}`;
  }
  return converted;
}

/**
 * Cópia em profundidade de um objeto em outro, ignorando campos
 * indefinidos do segundo.
 *
 * @param {object} obj1 Primeiro objeto, que deve receber os valores
 * @param {object} obj2 Segundo objeto, que deve fornecer valores novos
 * para uma ou mais propriedades
 *
 * @returns O primeiro objeto atualizado
 */
export function deepCopy(obj1, obj2) {
  for (const field in obj1) {
    const field1Type = typeof obj1[field];
    const field2Type = typeof obj2[field];
    const sameType = field1Type === field2Type;

    if (field2Type === 'undefined') {
      continue;
    }

    // Se ambos são mesmo tipos e objeto, entra na recursão
    if (sameType && field2Type === 'undefined') {
      return deepCopy(obj1[field], obj2[field]);
    }

    obj1[field] = obj2[field];
  }

  return obj1;
}

/**
 * Opens a popup in the center of the screen.
 *
 * @param {String} url The url of the popup
 * @param {String} title The title of the popup
 * @param {Number} w The width the popup should have
 * @param {Number} h The popup's height
 * @param {Boolean} focus If it should focus the popup as soon as it opens or not
 * @returns Window reference
 */
export function popupCenterScreen(url, title, w, h, focus) {
  w = w || screen.availWidth;
  h = h || screen.availHeight;
  const top = (screen.height - h) / 4,
    left = (screen.width - w) / 2;
  const popup = window.open(
    url,
    title,
    // eslint-disable-next-line max-len
    `toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,width=${w},height=${h},top=${top},left=${left}`
  );
  if (focus === true && window.focus) popup.focus();
  return popup;
}

/**
 * Use this to add the debounve functionality to any functions.
 *
 * Debounced functions will only be executed AFTER a given time has passed after
 * its last call. useful when a function can be called multiple times but should
 * process only once, in the end.
 *
 * @param {Function} fn The functioun to be debounced
 * @param {Number} wait The time to wait for it to stop
 * @returns Function
 */
export function debounce(fn, wait = 300) {
  let to = null;

  return (...args) => {
    clearTimeout(to);
    to = setTimeout((_) => fn(...args), wait);
  };
}

/**
 * Used to apply a throttle functionality to any function.
 *
 * Returns a function, that, when invoked, will only be triggered at most once
 * during a given window of time. Normally, the throttled function will run
 * as much as it can, without ever going more than once per `wait` duration;
 * but if you'd like to disable the execution on the leading edge, pass
 * `{leading: false}`. To disable execution on the trailing edge, ditto.
 *
 * @param {Function} func The function to be throttled
 * @param {Number} wait The time in mileseconds to be the interval
 * @param {Object} options Accepts some options (like the `leading` one)
 * @returns Function The throttled function
 */
export function throttle(func, wait, options) {
  var context, args, result;
  var timeout = null;
  var previous = 0;
  if (!options) options = {};
  var later = function () {
    previous = options.leading === false ? 0 : Date.now();
    timeout = null;
    result = func.apply(context, args);
    if (!timeout) context = args = null;
  };

  return function () {
    var now = Date.now();
    if (!previous && options.leading === false) previous = now;
    var remaining = wait - (now - previous);
    context = this; // eslint-disable-line
    args = arguments;
    if (remaining <= 0 || remaining > wait) {
      if (timeout) {
        clearTimeout(timeout);
        timeout = null;
      }
      previous = now;
      result = func.apply(context, args);
      if (!timeout) context = args = null;
    } else if (!timeout && options.trailing !== false) {
      timeout = setTimeout(later, remaining);
    }
    return result;
  };
}

/**
 * It should crop a given string to a specific size.
 *
 * @param {String} str The text to be cropped
 * @param {Number} limit The limit in size for the text
 * @param {Boolean} useEllipsis If it should add the ellipses (default is true)
 * @returns String
 */
export function cropString(str, limit = 30, useEllipsis = true) {
  if (str.length <= limit) {
    return str;
  }
  return str.substring(0, limit) + (useEllipsis ? '…' : '');
}

/**
 * Adds a script to the page's header if it has not been imported yet.
 *
 * @param {String} src The script source
 * @param {String} id An id for the script
 * @returns Promise
 */
export function createScript(src, id) {
  return new Promise((resolve, reject) => {
    if (!document.querySelector('script#' + id)) {
      const script = document.createElement('script');
      script.src = src;
      script.onload = function () {
        resolve();
      };
      script.onError = function (event) {
        reject(event);
      };
      document.head.appendChild(script);
    } else {
      resolve();
    }
  });
}

/**
 * Returns the name of the month for a given date.
 *
 * @param {Date} data The date object
 * @param {String} lang The language (default is pt-BR)
 * @param {Boolean} long If uses the long version of the month's name
 * @returns String
 */
export function getMonthName(date, lang = 'pt-BR', long = true) {
  return new Date(date).toLocaleString(lang, {
    month: long ? 'long' : 'short'
  });
}

/**
 * Will download a PDF (or other format, if specified) file based on its content.
 *
 * @param {Base64} pdfBase64 The base64 content for the PDF
 * @param {String} format The format of the content (default is "application/pdf")
 * @param {String} fileName The name of the file to be downloaded (default is "file.pdf")
 */
export const downloadPDF = (
  pdfBase64,
  format = 'application/pdf',
  fileName = 'file.pdf'
) => {
  const urlFile = `data:${format};base64,${pdfBase64}`;
  const link = document.createElement('a');

  link.href = urlFile;
  link.download = fileName;
  link.style.display = 'none';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

/**
 * Will try and download a file based on its url.
 *
 * @param {String} urlFile The address of the file to be downloaded
 * @param {String} filename The name of the file
 */
export const downloadFile = (urlFile, filename) => {
  var link = document.createElement('a');
  link.href = urlFile;
  link.download = filename;
  link.style.display = 'none';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

/**
 * Takes the file selected within an input of type file and
 * transforms it into a base64 string.
 *
 * @param {FileObject} file The file comming from the input
 */
export function inputToBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      resolve(reader.result);
    };
    reader.onerror = function (error) {
      reject(error);
    };
  });
}

/**
 * Returns the age based on a date.
 *
 * @param {String|Date} dateString The date of birth
 * @returns Number
 */
export function getAge(dateString) {
  const today = new Date();
  const birthDate =
    dateString instanceof Date ? dateString : new Date(dateString);
  let age = today.getFullYear() - birthDate.getFullYear();
  const m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
}

// Author https://github.com/manishsaraan/email-validator
// eslint-disable-next-line max-len
const tester =
  // eslint-disable-next-line max-len
  /^[-!#$%&'*+/0-9=?A-Z^_a-z`{|}~](\.?[-!#$%&'*+/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/;
/**
 * A function to validate e-mails.
 *
 * @param {string} email The email to be validated
 * @returns Boolean
 */
export function validateEmail(email) {
  if (!email) return false;

  const emailParts = email.split('@');

  if (emailParts.length !== 2) return false;

  const account = emailParts[0];
  const address = emailParts[1];

  if (account.length > 64) return false;
  else if (address.length > 255) return false;

  const domainParts = address.split('.');
  if (
    domainParts.some(function (part) {
      return part.length > 63;
    })
  )
    return false;

  if (!tester.test(email)) return false;

  return true;
}

/**
 * Will validate the CPF (document ID in Brasil)
 *
 * @param {String} cpf The cpf
 * @returns Boolean
 */
export function isValidCPF(cpf) {
  if (typeof cpf !== 'string') return false;
  cpf = cpf.replace(/[\s.-]*/gim, '');
  if (
    !cpf ||
    cpf.length != 11 ||
    cpf == '00000000000' ||
    cpf == '11111111111' ||
    cpf == '22222222222' ||
    cpf == '33333333333' ||
    cpf == '44444444444' ||
    cpf == '55555555555' ||
    cpf == '66666666666' ||
    cpf == '77777777777' ||
    cpf == '88888888888' ||
    cpf == '99999999999'
  ) {
    return false;
  }
  let soma = 0;
  let resto;
  for (let i = 1; i <= 9; i++) {
    soma = soma + parseInt(cpf.substring(i - 1, i)) * (11 - i);
  }
  resto = (soma * 10) % 11;
  if (resto == 10 || resto == 11) resto = 0;
  if (resto != parseInt(cpf.substring(9, 10))) return false;
  soma = 0;
  for (let i = 1; i <= 10; i++) {
    soma = soma + parseInt(cpf.substring(i - 1, i)) * (12 - i);
  }
  resto = (soma * 10) % 11;
  if (resto == 10 || resto == 11) resto = 0;
  if (resto != parseInt(cpf.substring(10, 11))) return false;
  return true;
}

export function monthDiff(DataInicio) {
  const [currentYear, currentMonth] = new Date().toISOString().match(/\d+/g);
  const [startYear, startMonth] = DataInicio.match(/\d+/g); // "YYYY-MM-DD"
  const dateFrom = new Date(startYear, startMonth);
  const dateTo = new Date(currentYear, currentMonth);
  return (
    dateTo.getMonth() -
    dateFrom.getMonth() +
    12 * (dateTo.getFullYear() - dateFrom.getFullYear())
  );
}

/**
 * Will open the share browser feature for mobile and desktop.
 *
 * @param {String} title The title to be shared
 * @param {String} text The text message shared
 * @param {String} share The url
 */
export const openShare = (title, text, share) => {
  if (navigator) {
    require('share-api-polyfill');
    navigator.share(
      {
        title,
        text,
        url: share
      },
      {
        print: false,
        facebook: false,
        twitter: false,
        linkedin: false,
        pinterest: false,
        language: 'pt'
      }
    );
  }
};

/**
 * Retrieves the value of a given variable from the URL.
 *
 * @param {String} variable The variable name
 * @param {String} url The url, source
 * @returns Any
 */
export function getQueryVar(variable = null, url = '') {
  url = url ? new URL(url) : window?.location;
  var query = url.search?.substring(1);
  var vars = query.split('&');
  const params = {};
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split('=');
    if (variable && decodeURIComponent(pair[0]) == variable) {
      return decodeURIComponent(pair[1]);
    }
    if (!variable) {
      params[pair[0]] = pair[1];
    }
  }
  return variable ? null : params;
}

/**
 * Returns an object with key=value pairs for every url comming from the URL.
 *
 * @param {String} url The url
 * @returns Object
 */
export function getQueryVars(url = '') {
  return getQueryVar(null, url);
}

/**
 * Used to add accessibility to allow elements to act like buttons or inputs
 * listening to the ENTER key.
 *
 * @param {Function} cb The callback function
 * @returns Function
 */
export function handleKeyboardA11y(cb) {
  return function (event) {
    if (event.key === 'Enter') {
      cb(event);
    }
  };
}

/**
 * Deals with the Google Maps API to treat the returned address object
 * and normalize it.
 *
 * @param {GMapsObject} gmapAddr The address object from GMaps
 * @returns Object
 */
export function parseGMapsAddress(gmapAddr) {
  const addr = {
    fullAddress: gmapAddr.formatted_address,
    geometry: gmapAddr.geometry
  };

  gmapAddr.address_components.forEach((comp) => {
    if (comp.types.includes('postal_code')) {
      addr.postal_code = comp.short_name;
      addr.cep = comp.short_name; // for retrocompatibility
      return;
    }
    if (comp.types.includes('route')) {
      addr.street = comp.short_name;
      addr.address = comp.short_name; // for retrocompatibility
      return;
    }
    if (comp.types.includes('sublocality_level_1')) {
      addr.neighborhood = comp.short_name;
      return;
    }
    if (comp.types.includes('administrative_area_level_2')) {
      addr.city = comp.short_name;
      return;
    }
    if (comp.types.includes('administrative_area_level_1')) {
      addr.uf = comp.short_name;
      addr.state = comp.long_name;
      return;
    }
    if (comp.types.includes('country')) {
      addr.countryId = comp.short_name;
      addr.country = comp.long_name;
      return;
    }
  });

  return addr;
}

/**
 * This function will load the address information for a given postal code and
 * return an Object (or a list of objects) with matching addresses.
 *
 * @param {String} postalCode The postal code itself
 * @param {String} country The country Code (default is "BR")
 * @returns [Object] The address objects
 */
export async function getAddressByPostalCode(postalCode) {
  const res = await fetch(
    `/api/address/by-postal-code?postal_code=${postalCode}`
  );
  return await res.json();
}

/**
 * Will use the parsed Address object to retrieve geo coordinates.
 *
 * @param {Object} address The address parsef Object
 * @returns [Coords] The found coords with coordLat and coordLng
 */
export async function getCoordsForAddress(address) {
  const addrStr =
    address.number +
    ', ' +
    (address.address || address.street) +
    ' - ' +
    address.city +
    ', ' +
    (address.uf || address.state);
  const res = await fetch(`/api/address/coords-by-address?address=${addrStr}`);
  const data = await res.json();
  const results = [];
  if (data.results && data.results.length) {
    const loc = data.results[0].geometry.location;
    results.push({
      coordLat: loc.lat,
      coordLng: loc.lng
    });
  }
  return results;
}

/**
 * Will try and find the block window containing the given element.
 *
 * @param {DOMElement} domEl The element within the block window
 * @returns BlockWindow Object
 */
export function findWindowFromChildElement(domEl) {
  const SAFE_LOOP_LIMIT = 99;
  let i = 0;
  do {
    i++;
    if (domEl.dataset.windowId) {
      return domEl.dataset.windowId;
    }
    domEl = domEl.parentElement;

    if (i > SAFE_LOOP_LIMIT) return null;
  } while (!domEl.dataset.windowId || domEl !== window);
  return null;
}

/**
 * Will try to store the given data to the user's clipboard.
 *
 * @param {Any} value The value to be coppied
 * @param {Boolean} blob If it should be written as a blob/base64
 * @returns Promise
 */
export function copyToClipboard(value, blob = false) {
  if (navigator && navigator.clipboard && navigator.clipboard.writeText) {
    if (blob) {
      return navigator.clipboard.write([value]);
    }
    return navigator.clipboard.writeText(value);
  }
  return Promise.reject('The Clipboard API is not available.');
}

export function getFocusableElement(container) {
  const focussableElements = `a:not([disabled]), button:not([disabled]), input[type=text]:not([disabled]), [tabindex]:not([disabled]):not([tabindex="-1"])`;
  return container?.querySelector(focussableElements) || null;
}

/**
 * Ensures a function will take at least a given time to finish.
 * Specially useful for loaders or easing user interactions
 *
 * (the proccess will be over, but the result will only be available when the time ends)
 *
 * @param {Function} fn The function to be triggered after the specified time
 * @param {Number} timeout The time to wait for
 * @returns Promise
 */
export async function waitAtLeast(fn, timeout = 1000) {
  const ps = await Promise.all([
    fn,
    new Promise(
      (resolve) =>
        function () {
          setTimeout(resolve, timeout);
        }
    )
  ]);
  return ps[0];
}

/**
 * Receives an array and will divide it into chunks, returnin an Array of
 * Arrays with the same size.
 * The last item may have less items.
 *
 * @param {Array} arr The array to be chunked
 * @param {Number} size The size of each chunk
 * @returns Array
 */
export function arrayChunk(arr, size) {
  return Array.from(
    {
      length: Math.ceil(arr.length / size)
    },
    (_, i) => {
      return arr.slice(i * size, i * size + size);
    }
  );
}

export function parseAddressFromFields(fieldDefinitionAddr, valueAddr) {
  const validKeys = Object.keys(fieldDefinitionAddr);
  const parsedValue = {};
  validKeys.forEach((vk) => {
    parsedValue[vk] = valueAddr[vk];
  });

  return parsedValue;
}

/**
 * Receives an array and reorder it.
 *
 * @param {Array} arr The array with the table columns
 * @returns Array
 */
export const itemsToUpdate = (rows) =>
  rows.reduce((acc, row, index) => {
    if (row.order !== index + 1) {
      return [
        ...acc,
        {
          ...row,
          order: index + 1
        }
      ];
    }
    return acc;
  }, []);

export const isValidCEP = (zipcode) => {
  const zipcodeRegex = /^[0-9]{5}-?[0-9]{3}$/;
  return zipcodeRegex.test(zipcode);
};

export const removeCollectionExtraSpaces = (data) => {
  for (const key in data) {
    if (typeof data[key] == 'string') data[key] = data[key].trim();
  }
  return data;
};

export const isNumber = (number) => {
  return !isNaN(parseFloat(number));
};
